<style scoped>
  .section-search-content{
    /*background-color:#fff;*/
    background-color: rgb(246 247 251);
    padding: 40px 0;
  }
  .event-details-wrapper{
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding: 30px 30px 20px;
    box-shadow: 0px 15px 50px rgb(0 0 0 / 7%);
  }
  .nearest-event-section{
    margin-bottom: 30px;
  }
  .event-details-wrapper .event-details-rgt-side{
    width: 100%;
  }
  .section-search-content .search-result-header{
    padding:0 0px 20px;
    margin:0 0 30px;
    border-bottom: 1px solid #ccc;
  }
  .section-search-content .search-result-header .row{
    align-items: center;
  }
  .section-search-content .search-result-header h1{
    color:#04092c;
    margin:0;
    font-family:'Heebo', sans-serif!important;
    font-weight:600;
    font-size:24px
  }
  .section-search-content .search-result-header label{
    color:#04092c;
    font-family:'Heebo', sans-serif!important;
    font-weight:600;
    font-size:17px;
    width:auto;
    margin-right: 8px;
    text-align: right;
  }
  .event-filtering-section .event-filter-options:not(:last-child){
    margin-right: 15px;
  }
  .event-filter{
    order: 2;
  }
  .select-city{
    order: 3;
  }
  .common-dropdown-style{
    position: relative;
  }
  .city-icon{
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
  }
  .section-search-content .search-result-item{
    padding: 10px 15px;
    margin:0 0 15px;
    border-radius:10px;
    background-color: #fff;
    /*background-color: rgb(245 161 0/6%);*/
    position: relative;
    /*overflow: hidden;*/
    transition:all .4s ease-in-out;
    -webkit-transition:all .4s ease-in-out;
    -moz-transition:all .4s ease-in-out;
    -o-transition:all .4s ease-in-out;
    -ms-transition:all .4s ease-in-out;
    cursor:pointer;
    /*box-shadow: 0 0 20px rgb(195 195 195 / 30%);*/
    border: 1px solid #eee;
  }
  /*.section-search-content .search-result-item:before{
    content: '';
    position: absolute;
    left: 0px;
    bottom: -14px;
    width: 100%;
    height: 1px;
    background-color: #ccc;
  }*/
  .section-search-content .search-result-item:hover,
  .section-search-content .search-result-item:focus{
    /*box-shadow: 0 0 10px 0 rgb(195 195 195 / 70%);*/
    /*background-color: rgb(245 161 0/10%);*/
    background-color: #f4f4f4;
    box-shadow: none;
  }
  .section-search-content .search-result-item:hover .event-list .event-date .date-wrapper{
    /*background-color: rgb(240 129 0);*/
    background-color: #fff;
  }
  .section-search-content .search-result-item .row{
    align-items:center;
    justify-content:space-between
  }
  .event-list{
    display:flex;
    align-items:center;
    justify-content:space-between
  }
  .event-list .event-date{
    width: 100px;
  }
  .event-list .event-date .date-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    /*background-color: #efefef;*/
    background-color: #f3f3f3;
    border-radius: 10px;
    padding: 15px 10px;
    transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
  }
  .event-list .event-date .date-wrapper .date{
    font-size: 28px;
    line-height: 36px;
    color: #737373;
    font-weight: 700;
    transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
  }
  .event-list .event-date .date-wrapper .month-year{
    font-size: 14px;
    line-height: 22px;
    transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    -moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -ms-transition: all .4s ease-in-out;
  }
  .event-list .event-date.date-tbd .full-date{
    width: auto;
    color: #737373;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
  }
  .event-list .event-date.date-tbd + .event-details{
  }
  .event-list .date-time{
    display:flex;
    align-items:center
  }
  .event-list .date-time span{
    position:relative;
    color: #929292;
    font-size:14px;
    line-height: 14px;
    display: flex;
    align-items: center;
  }
  .event-list .date-time span:first-child{
    margin-right:16px;
    position: relative;
  }
  /*.event-list .date-time span:first-child:before{
    content: '';
    position: absolute;
    right: -9px;
    top: 50%;
    transform: translateY(-50%);
    width: 2px;
    height: 12px;
    background-color: #f08100;
  }*/
  .event-list .date-time span .venue-day-icon,
  .event-list .date-time span .venue-time-icon{
    width: 14px;
    margin-right: 6px;
    vertical-align: baseline;
  }
  .starts-from{
    display: flex;
    flex-direction: column-reverse;
    width: 150px;
    margin: 0 0 10px auto;
    align-items: center;
    justify-content: flex-end;
  }
  .starts-from .start-label{
    font-size: 12px;
    line-height: 18px;
    color: #929292;
    margin-top: -5px;
    /*color: #fff;*/
    /*background-color: #929292;*/
  }
  .starts-from .event-start-price{
    margin-bottom: 0px;
    font-size: 28px;
    line-height: 36px;
    color: #f08100;
    font-weight: 700;
  }
  .event-list .event-details{
    width:calc(100% - 120px)
  }
  .section-search-content .search-result-item .search-result-item-info .venue-title{
    color:#04092c;
    font-weight:800;
    padding:0;
    border:0;
    font-size:18px;
    line-height:24px;
    margin:0 0 10px 0;
    display: inline-block;
    vertical-align: bottom;
    cursor: default;
  }
  .search-result-item-info .venue-address-details{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .search-result-item-info .venue-address-details span{
    font-size: 15px;
    line-height: normal;
    color: #929292;
    position: relative;
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    cursor: default;
  }
  .search-result-item-info .venue-address-details span:not(:last-child){
    margin-right: 16px;
  }
  .search-result-item-info .venue-address-details .venue-name:before,
  .search-result-item-info .venue-address-details .venue-state:before,
  .search-result-item-info .venue-address-details .venue-name:after,
  .search-result-item-info .venue-address-details .venue-state:after,
  .event-list .date-time .event-day:before,
  .event-list .date-time .event-time:before,
  .event-list .date-time .event-day:after,
  .event-list .date-time .event-time:after{
    display: none ;
  }
  .search-result-item-info .venue-address-details .venue-name:hover:before,
  .search-result-item-info .venue-address-details .venue-state:hover:before,
  .search-result-item-info .venue-address-details .venue-name:hover:after,
  .search-result-item-info .venue-address-details .venue-state:hover:after,
  .event-list .date-time .event-day:hover:before,
  .event-list .date-time .event-time:hover:before,
  .event-list .date-time .event-day:hover:after,
  .event-list .date-time .event-time:hover:after{
    display: inline-block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
  }
  .search-result-item-info .venue-address-details .venue-name:before{
    content: 'Venue Address';
  }
  .search-result-item-info .venue-address-details .venue-state:before{
    content: 'Venue Country';
  }
  .event-list .date-time .event-day:before{
    content: 'Event Day';
  }
  .event-list .date-time .event-time:before{
    content: 'Event Time';
  }
  .search-result-item-info .venue-address-details .venue-name:before,
  .search-result-item-info .venue-address-details .venue-state:before,
  .event-list .date-time .event-day:before,
  .event-list .date-time .event-time:before{
    background-color: #000;
    color: #fff;
    padding: 3px 10px;
    font-size: 13px;
    line-height: 18px;
    border-radius: 5px;
    text-align: center;
    bottom: -30px;
    z-index: 1;
  }
  .search-result-item-info .venue-address-details .venue-name:before,
  .search-result-item-info .venue-address-details .venue-state:before{
    min-width: 150px;
  }
  .event-list .date-time .event-day:before,
  .event-list .date-time .event-time:before{
    min-width: 100px;
  }
  .search-result-item-info .venue-address-details .venue-name:after,
  .search-result-item-info .venue-address-details .venue-state:after,
  .event-list .date-time .event-day:after,
  .event-list .date-time .event-time:after{
    content: '';
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #000;
    z-index: 2;
    bottom: -6px;
  }
  .search-result-item-info .venue-address-details .venue-icon{
    width: 17px;
    margin-right: 6px;
    margin-top: 2px;
    vertical-align: text-top;
  }
  .innerpage-banner{
    height:150px;
    position: relative;
  }
  .innerpage-banner:after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: 0 0;
    background-color: rgba(0,0,0,.5);
  }
  /*.innerpage-banner img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }*/
  .innerpage-banner.event-page-banner{
    background-image:url('~@/../public/images/share-action.webp');
  }
  .innerpage-banner .section-header{
    -webkit-box-align:center;
    -ms-flex-align:center;
    align-items:center;
    display:-webkit-box;
    display:-ms-flexbox;
    display:flex;
    -ms-flex:1;
    flex:1;
    height:100%;
    max-width:100%;
    position:absolute;
    top: 0;
    width:100%;
    justify-content:center;
    flex-direction: column;
    padding: 0 10px;
  }
  .innerpage-banner .section-header>div:first-child{
    z-index: 1;
  }
  .innerpage-banner .section-header h1{
    color:#fff;
    font-family:'Heebo', sans-serif !important;
    font-weight:700;
    font-size: 36px;
    text-align: center;
    margin-bottom: 8px;
    z-index: 1;
  }
  .performer-header{
    flex-direction:column
  }
  .performer-header h1{
    margin-bottom:20px;
  }
  .show-more-btn{
    background-color:rgb(240 129 0/20%);
    color:#f08100;
    border-radius:6px;
    padding: 12px;
    min-width: 160px;
    font-family:'Heebo', sans-serif;
    font-weight:600;
    display:block;
    font-size:16px;
    line-height:normal;
    -moz-transition:all .3s ease-in-out 0s;
    -webkit-transition:all .3s ease-in-out 0s;
    -ms-transition:all .3s ease-in-out 0s;
    -o-transition:all .3s ease-in-out 0s;
    transition:all .3s ease-in-out 0s;
    border:0
  }
  .show-more-btn:hover{
    background-color:rgb(240 129 0);
    color:#fff
  }
  .section-search-content .search-result-item .search-result-item-price button{
    border:0;
    /*background-color:rgb(240 129 0/20%);*/
    background-color: rgb(240 129 0/70%);
    color:#fff;
    padding:10px 12px;
    margin-left:auto;
    font-family:'Heebo', sans-serif;
    font-weight:600;
    min-width: 150px;
    display:block;
    border-radius:6px;
    font-size:15px;
    line-height:normal;
    -moz-transition:all .3s ease-in-out 0s;
    -webkit-transition:all .3s ease-in-out 0s;
    -ms-transition:all .3s ease-in-out 0s;
    -o-transition:all .3s ease-in-out 0s;
    transition:all .3s ease-in-out 0s
  }
  .section-search-content .search-result-item .search-result-item-price button:hover{
    background:#f08100
  }
  .load-more-spinner{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .load-more-spinner i{
    color:#f08100;
    font-size:30px
  }
  .dis-none{
    display:none!important
  }
  .no-records-found{
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    text-align: center;
    margin: 0 0 0px 0;
  }
  .event-filtering-options{
    padding: 15px 0;
    box-shadow: 0px 10px 15px rgb(0 0 0 / 7%);
    display: flex;
    width: 100%;
  }
  .event-filtering-options .event-main-filter-wrapper{
    display: flex;
    justify-content: flex-end;
  }
  .event-details-page-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
  }
  .event-details-sidebar-wrapper + .event-details-main-wrapper{
    width: calc(100% - 280px);
    margin-right: 30px;
  }
  .event-details-main-wrapper{
    width: 100%;
  }
  .event-details-sidebar-wrapper{
    width: 250px;
    border-radius: 10px;
    box-shadow: 0px 15px 50px rgb(0 0 0 / 7%);
    background-color: #fff;
  }
  .related-event-header{
    padding: 30px 15px 20px;
    border-bottom: 1px solid #ccc;
    font-size: 24px;
    line-height: 28px;
    margin: 0px;
  }
  .related-events-list-wrapper{
    padding: 20px 10px;
  }
  .related-event-item a{
    display: flex;
    padding: 5px 10px;
    font-size: 16px;
    line-height: 24px;
    border-radius: 6px;
    border: 1px solid #eee;
    font-weight: 500;
  }
  .related-event-item a:hover,
  .related-event-item a.router-link-active,
  .related-event-item a:focus{
    /* color: #ea3708 !important;
    background-color: rgb(234 55 8/20%) !important;
    border-color: #ea3708; */
      color: #f08100 !important;
      background-color: #f4f4f4 !important;
      font-weight: 500;
      border-color: #f4f4f4;
  }
  .related-event-item a.router-link-active{
    font-weight: 500;
  }
  .related-event-item:not(:last-child) a{
    margin-bottom: 12px;
  }
  .mobile-similar-performers{
    display: none;
  }
  /*.global-search-autocomplete .event-list .event-date .full-date{
    font-size: 13px;
    line-height: 20px;
    text-align: left;
    color: #f08100;
    display: inline-block;
    font-family: Heebo, sans-serif;
    font-weight: 600;
    margin: 0 0 5px 0;
  }*/
  .global-search-autocomplete .event-list .event-date {
    margin-right: 5px;
  }
  .global-search-autocomplete .event-list .event-details {
    text-align: left;
  }
  .global-search-autocomplete .event-list .date-time {
    display: flex;
    align-items: flex-start;
  }
  .global-search-autocomplete .event-list .date-time span {
    font-size: 13px;
    line-height: 13px;
  }
  .global-search-autocomplete .event-list .date-time span:first-child{
    position: relative;
    margin-right: 8px;
  }
  .global-search-autocomplete .event-list .date-time span:first-child:before {
    content: '';
    position: absolute;
    right: -7px;
    top: 7px;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #f08100;
  }
  .global-search-autocomplete .event-list .date-time span img{
    vertical-align: middle;
    max-height: 13px;
    margin-right: 6px;
  }
  .search-wrapper{
    position: relative;
    z-index: 2;
  }
  .search-wrapper .header-search{
    position: relative;
  }
  .search-wrapper .header-search .search-btn{
    position:absolute;
    left:15px;
    top:13px;
    border:0;
    padding:0;
    color:#fff;
    line-height:14px;
    width:17px;
    height:17px;
    background-image:url('~@/../public/images/header-search-icon.webp');
    background-repeat:no-repeat;
    background-position:center;
    background-size:17px;
    background-color: transparent;
  }
  .global-search-blur-banner h1{
    z-index: 0;
  }
  .nearest-event-section.mobile-dis-none{
    display: none;
  }
  @media (min-width: 1024px){
    .innerpage-banner{
      height:180px
    }
  }
  @media (max-width: 1280px){
    .innerpage-banner .container{
      max-width:100%
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px){
    .section-search-content .search-result-header h1 {
      font-size: 22px
    }
  }
  @media (max-width: 1200px){
    .search-wrapper .header-search .search-btn{
      left: 10px;
      top: 12px;
      width: 15px;
      height: 15px;
      background-size: 15px;
    }
  }
  @media (max-width: 1199px){
    .event-details-page-wrapper{
      flex-wrap: wrap;
    }
    .event-details-sidebar-wrapper{
      width: 100%;
    }
    .event-details-sidebar-wrapper + .event-details-main-wrapper{
      width: 100%;
      margin-right: 0px;
      margin-top: 25px;
    }
    .related-event-header{
      font-size: 20px;
      line-height: 26px;
      padding: 20px;
    }
    .event-details-wrapper{
      padding: 20px;
    }
    .section-search-content .search-result-header{
      margin:0 0 20px;
      padding: 0 0px 15px;
    }
    .related-event-item a{
      font-size: 15px;
      line-height: 22px;
    }
    .related-events-list-wrapper{
      padding: 15px 10px;
    }
    .no-records-found{
      font-size: 22px;
      line-height: 26px;
    }
    .section-search-content .search-result-item .row .search-result-item-price button{
      display: none;
    }
    .section-search-content .search-result-header .row{
      justify-content: space-between;
    }
    .section-search-content .search-result-item .search-result-item-info{
      width: 100%;
    }
    .event-filtering-section .event-filter-options[data-v-65fc07c5]:not(:last-child) {
      margin-right: 10px;
    }
    .search-wrapper .header-search .search-btn{
      width: 15px;
      height: 15px;
      background-size: 15px;
      top: 12px;
      left: 10px;
    }
    .similar-performer-desktop{
      display: none;
    }
    .mobile-similar-performers{
      display: block;
    }
    .mobile-similar-performers .accordion-item{
      border: 0px;
      background-color: #fff;
      box-shadow: 0px 15px 50px rgb(0 0 0 / 7%);
      border-radius: 10px;
    }
    .mobile-similar-performers .accordion-header .accordion-button{
      padding: 15px;
      font-size: 18px;
      line-height: 24px;
      font-weight: 600;
      color: #04092c;
      background-color: transparent;
    }
    .mobile-similar-performers .accordion-header .accordion-button:not(.collapsed){
      box-shadow: none;
    }
    .mobile-similar-performers .accordion-header .accordion-button:focus{
      border-color: transparent;
      box-shadow: none;
    }
    .mobile-similar-performers .accordion-header .accordion-button:after{
      background-image: url('~@/../public/images/select-arrow.png');
      width: 16px;
      height: 8px;
      background-size: cover;
      transform: rotate(0);
    }
    .mobile-similar-performers .accordion-header .accordion-button[aria-expanded="true"]:after{
      transform: rotate(180deg);
    }
    .mobile-similar-performers .similar-performers-listing{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 10px 5px;
      border-top: 1px solid #ccc;
    }
    .mobile-similar-performers .accordion-collapse .accordion-body{
      width: 33%;
      display: flex;
      font-size: 16px;
      line-height: 24px;
      border-radius: 6px;
      padding: 0px;
    }
    .mobile-similar-performers .accordion-collapse .accordion-body a{
      padding: 4px 10px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 6px;
      border: 1px solid #eee;
      margin: 0 5px 8px;
      font-weight: 500;
    }
    .mobile-similar-performers .accordion-collapse .accordion-body a:hover{
      color: #f08100 !important;
      background-color: #f4f4f4;
      border-color: #f4f4f4;
    }
    .section-search-content .search-result-item .search-result-item-info {
      width: calc(100% - 160px);
    }
    .section-search-content .search-result-item .search-result-item-price{
      width: 150px;
      padding: 0;
    }
  }
  @media (max-width: 1024px){
    .innerpage-banner .section-header h1{
      font-size:32px
    }
  }
  @media (max-width: 991px) {
    .event-details-wrapper{
      flex-wrap:wrap;
    }
    .date-range-picker .event-date-picker .date-icon{
      font-size: 14px;
      left: 8px;
    }
    .related-events-list-wrapper{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .related-events-list-wrapper .related-event-item{
      width: 33%;
      display: flex;
      align-items: stretch;
      justify-content: center;
    }
    .related-event-item a{
      width: 100%;
      margin: 0 5px;
      text-align: center;
      justify-content: center;
      align-items: center;
    }
    /* .mobile-similar-performers .accordion-collapse .accordion-body a:hover,
    .mobile-similar-performers .accordion-collapse .accordion-body a.router-link-active{
      color: #ea3708 !important;
      background-color: rgb(234 55 8/20%) !important;
      font-weight: 500;
    } */
    .global-search-autocomplete .event-list .date-time span:first-child {
      margin-bottom: 0;
      margin-right: 12px;
    }
    .event-list {
      align-items: flex-start;
    }
    .starts-from {
      margin-bottom: 0;
    }
    .search-result-item-info .venue-address-details span:not(:last-child){
      margin-right: 0px;
    }
    .section-search-content .search-result-item .search-result-item-info .venue-title{
      margin-bottom: 6px;
    }
    .mobile-similar-performers .accordion-collapse .accordion-body{
      font-size: 14px;
      line-height: 22px;
    }
  }
  @media (max-width: 768px) {
    .search-result-header .row{
      flex-direction:column;
      align-items:flex-start;
    }
    .section-search-content .search-result-header .row>div{
      width:100%;
      margin: 0 0 20px;
    }
    .section-search-content .search-result-item .search-result-item-info .venue-title{
      font-size:16px;
      line-height:22px
    }
    .innerpage-banner .section-header h1{
      font-size:24px;
      margin-bottom: 5px;
    }
  }
  @media (max-width: 767px) {
    .nearest-event-section .section-search-content .search-result-header{
      margin:0 0 40px;
    }
    .search-result-header .row{
      flex-wrap:wrap;
    }
    .section-search-content .search-result-item .row{
      position:relative;
      margin:0
    }
    .event-list .event-date .full-date{
      font-size:15px;
      line-height:22px
    }
    .event-list .date-time span,
    .starts-from .start-label{
      font-size:13px;
      line-height: 13px;
    }
    .innerpage-banner .container{
      max-width:100%
    }
    .show-more-btn{
      padding: 10px 20px;
      width:140px
    }
    .section-search-content .search-result-header h1 {
      font-size: 20px
    }
    .event-details-wrapper{
      padding: 20px 12px;
    }
    .mobile-similar-performers .similar-performers-listing{
      justify-content: flex-start;
      max-height: 200px;
      overflow-y: auto;
    }
    .mobile-similar-performers .accordion-collapse .accordion-body{
      width: 50%;
    }
    .mobile-similar-performers .accordion-header .accordion-button{
      padding: 10px 12px;
      font-size: 16px;
      line-height: 22px;
    }
    .starts-from .event-start-price{
      font-size: 18px;
      line-height: 1.4;
    }
    .event-list .event-date .date-wrapper{
      padding: 15px 3px;
      border-radius: 5px;
    }
    .event-list .event-date .date-wrapper .date {
      font-size: 18px;
      line-height: 1.2;
    }
    .event-list .event-date {
      width: 80px;
    }
    .event-list .event-details {
      width: calc(100% - 90px);
    }
    .search-result-item-info .venue-address-details span {
      white-space: normal;
      display: inline-block;
      width: 100%;
    }
    .section-search-content .search-result-item .search-result-item-price {
      width: 100px;
    }
    .section-search-content .search-result-item .search-result-item-price .starts-from {
      width: 100%;
    }
    .section-search-content .search-result-item{
      min-height: 150px;
      padding: 8px 0px;
    }
    .section-search-content .search-result-item .search-result-item-info{
      width: 100%;
      position: relative;
      z-index: 1;
    }
    .section-search-content .search-result-item .search-result-item-price{
      width: 100%;
      padding: 12px;
      position: absolute;
      top: 70px;
      z-index: 1;
    }
    .section-search-content .search-result-item .search-result-item-price .starts-from{
      width: 80px;
      border-radius: 4px;
      justify-content: flex-start;
      margin-left: 0;
      background-color: rgb(240 129 0/70%);
      padding: 4px;
    }
    .starts-from .start-label{
      color: #fbefc2;
      font-size: 11px;
    }
    .starts-from .event-start-price{
      color: #fff;
      font-size: 16px;
      margin-bottom: 3px;
    }
    .search-result-item-info .venue-address-details{
      z-index: 2;
    }
    .search-result-item-info .venue-address-details span{
      margin-bottom: 6px;
    }
  }
  @media (max-width: 575px) {
    .innerpage-banner .section-header h1{
      font-size: 22px;
    }
    .section-search-content{
      padding: 35px 0px;
    }
    .nearest-event-section{
      margin-bottom: 25px;
    }
    .section-search-content .search-result-header label{
      font-size: 15px;
    }
    .section-search-content .search-result-item .row{
      flex-direction:column;
    }
    .event-list .date-time span,
    .starts-from .start-label{
      font-size:12px;
      line-height:12px;
    }
    .performer-header h1{
      margin-bottom:15px
    }
    .performer-header .search-wrapper{
      width:100%
    }
    .no-records-found{
      font-size: 18px;
    }
    .search-wrapper{
      width: 100%;
      display: flex;
    }
    .search-wrapper .header-search{
      width: 100%;
    }
    .starts-from .event-start-price{
      font-size: 15px;
      line-height: 22px;
    }
    .event-list .event-date{
      width: 60px;
      text-align: center;
    }
    .event-list .event-details{
      width: calc(100% - 60px);
      padding-left: 10px;
    }
    .event-list .event-date .date-wrapper .date{
      font-size: 14px;
    }
    .event-list .event-date .date-wrapper .month-year{
      font-size: 12px;
      line-height: 20px;
    }
    .event-list .event-date .date-wrapper{
      padding: 8px 0px;
    }
    .section-search-content .search-result-item .search-result-item-info{
      padding: 6px;
    }
    .section-search-content .search-result-item .row{
      padding: 0;
    }
    .section-search-content .search-result-item{
      padding: 0;
      min-height: 125px;
      margin-bottom: 10px;
    }
    .section-search-content .search-result-item .search-result-item-price .starts-from{
      width: 60px;
    }
    .section-search-content .search-result-item .search-result-item-price{
      padding: 6px;
      top: 63px;
      text-align: center;
    }
    .starts-from .start-label{
      font-size: 10px;
    }
    .section-search-content .search-result-item .search-result-item-info .venue-title{
      font-size: 14px;
      line-height: 1.4;
      margin-bottom: 5px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .search-result-item-info .venue-address-details span{
      font-size: 13px;
    }
    .search-result-item-info .venue-address-details .venue-icon{
      max-height: 15px;
    }
  }
  @media (max-width: 480px) {
    .section-search-content{
      padding: 30px 0px;
    }
    .nearest-event-section{
      margin-bottom: 25px;
    }
    .event-list .event-date .date-wrapper{
      display:flex;
      flex-wrap:wrap;
    }
    .event-list .event-date .full-date{
      font-size:14px;
      line-height:20px
    }
    .event-list .date-time{
      flex-wrap:wrap
    }
    .section-search-content .search-result-item .search-result-item-info .venue-address{
      font-size:13px;
      line-height: 18px;
    }
    .section-search-content .search-result-header .row>div{
      margin: 0 0 15px;
    }
    .section-search-content .search-result-header h1 {
      font-size: 18px
    }
    .mobile-similar-performers .accordion-collapse .accordion-body:not(:last-child){
      margin-bottom: 3px;
    }
    .mobile-similar-performers .accordion-collapse .accordion-body{
      width: 100%;
    }
    /*.global-search-autocomplete .event-list .event-date .full-date {
      font-size: 12px;
      line-height: 18px;
    }*/
    .starts-from .event-start-price{
      font-size: 14px;
      line-height: 20px;
    }
  }
  @media (max-width: 400px) {
    .innerpage-banner .section-header h1{
      font-size: 20px;
    }
    /* .event-list{
      flex-direction: column-reverse;
      position: relative;
    } */
    .section-search-content .search-result-header{
      padding:0 0px 15px;
      margin: 0 0 22px;
    }
    /* .section-search-content .search-result-item .search-result-item-info{
      padding: 0 7px;
    } */
    /* .section-search-content .search-result-item{
      padding:8px 0px;
    } */
    /* .event-list .event-date,
    .event-list .event-details{
      width:100%;
    } */
    /* .event-list .event-details{
      margin-top: 25px;
    } */
    /* .section-search-content .search-result-item .search-result-item-info .venue-title{
      margin: 0 0 7px 0;
    } */
    /* .event-list .event-date{
      margin: 3px 0 0px 0;
    } */
    .event-list .date-wrapper{
      display:flex;
      flex-direction:row
    }
    .global-search-autocomplete .event-list .date-time span {
      font-size: 12px;
      line-height: 12px;
    }
    .global-search-autocomplete .event-list .date-time span:first-child {
      margin-bottom: 5px;
    }
    .event-date .starts-from{
      margin: 0px 0 0 15px;
    }
    /* .event-list .date-time span, .starts-from .start-label{
      font-size: 12px;
      line-height: 17px;
    } */
    .starts-from .event-start-price{
      font-size: 13px;
      line-height: 18px;
    }
    .date-tbd.event-date .starts-from{
      margin: 0px;
    }
    .event-list .date-time{
      position: relative;
    }
    .event-list .date-time span:first-child:before{
      top: 2px;
      transform: none;
    }
    /* .event-list .date-time:before{
      content: '';
      position: absolute;
      right: -9px;
      top: 2px;
      width: 2px;
      height: 12px;
      background-color: #f08100;
    } */
  }
  @media (min-width:320px) and (max-width:915px) and (orientation:landscape){
    .innerpage-banner .section-header.performer-header h1{
      font-size: 28px;
      margin-bottom: 15px;
    }
    .performer-header h1{
      margin-bottom: 15px;
    }
  }
  </style>
  <template>
    <section class="innerpage-banner event-page-banner">
      <!-- <v-lazy-image src="../../images/share-action.webp" alt="image" width="1920" height="235"></v-lazy-image> -->
      <div class="section-header">
        <template v-if="this.$route.params.performerId">
          <h1 v-for="(performer, index) in singlePerformer" :key="index">
            Events for {{ performer.name }}
          </h1>
        </template>
        <h1 v-else-if="this.$route.query.q">{{bannerTitle}}</h1>
        <h1 v-else-if="this.$route.params.venueId">Events for {{singleVenueName}}</h1>
        <h1 v-else-if="this.$route.params.regionId">Events for {{singleRegionName}}</h1>
        <h1 v-else>Events for you</h1>
        <!-- <h2 v-if="this.$route.query.q">Events for {{ slugToString(this.$route.query.q) }}</h2>
        <h2 v-else>Events for you</h2> -->
        <div class="search-wrapper mobile-search-icon-j">
          <div class="hero-search header-search">
            <!-- <EventSearchAutocomplete
              ref="performerSearchInput"
            />
            <input style="display: none" v-on:keyup.enter="redirectToSearchEvents" type="text" placeholder="Search Events" ref="redirectToSearchEventsInput" :value="this.$route.query.q">
            <button style="display: none" v-on:click="redirectToSearchEvents" class="search-btn" type="submit"></button> -->
            <div class="global-search-autocomplete serchnewdesign">
              <input
                id="mobileEventSearchInput"
                type="text"
                @input="onChange"
                v-model="search"
                @keydown.down="onArrowDown"
                @keydown.up="onArrowUp"
                @keydown.enter="onEnter"
                class="mobile-event-auto-search mobile-event-search-jquery"
                ref="mobileEventSearchInput"
                placeholder="Search for performers, events and venues"
                @focus="blurBackground"
                @blur="removeBlurFromBody"
              />
              <button class="search-cancel-btn mobile-search-cancel" v-bind:class="{ none: isGlobalSearchNotInFocus }">
                <img src="/images/close-icon.svg">
              </button>
              <ul id="autocomplete-results" v-show="isOpen" class="autocomplete-results">
                <li class="loading autocomplete-loader" v-if="isLoading">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                </li>
                <div class="global-search-content-wrap" v-if="results.performers">
                  <p class="no-records-found" v-if="results.performers.length===0 && results.events.length===0 && results.venues.length===0">No Results found !</p>
                  <h3 class="search-title" v-if="results.performers.length>0">Performers</h3>
                  <a :href="`/events/${result.name}/${result.performerId}`"
                    v-for="(result, i) in results.performers"
                    :key="i"
                    class="performers-listing"
                    :class="{ 'is-active': i === arrowCounter }"
                  >
                  <span class="full-date">{{ result.name }}</span>
                  </a>
                  <h3 class="search-title search-event-title"  v-if="results.events.length>0">Events</h3>
                  <a
                    href="javascript:void(0)"
                    v-for="(result, i) in results.events"
                    :key="i"
                    @click="setResult(result)"
                    class="autocomplete-result search-result-item"
                    :class="{ 'is-active': i === arrowCounter }"
                    v-on:click="redirectToEventDetailPage" :data-href="'/event/' + result.eventId + '/' + result.slug" :data-event-name="result.eventName" :data-venue-name="result.venue.name" :data-venue-city="result.venue.city" :data-venue-state="result.venue.state" :data-venue-timezone="result.venue.timezone" :data-venue-countrycode="result.venue.countryCode" :data-event-date="result.eventDateString"
                  >
                    <div class="search-result-item-info">
                      <div class="event-list">
                        <div class="event-date">
                          <div class="date-wrapper">
                            <span class="full-date dateonly">{{ formatEventDateOnly(result.eventDateString) }}</span>
                            <span class="full-date">{{ formatEventDateMonthYear(result.eventDateString) }}</span>
                          </div>
                        </div>
                        <div class="event-details">
                          <h3 class="venue-title">{{ result.eventName }}</h3>
                          <div class="full_add">
                            <span class="venue-address"><img class="venue-icon" src="/images/venue-map-icon.svg"><span>{{ result.venue.name }}, {{ result.venue.city }}</span></span>
                            <span class="venue-address"><img class="venue-icon" src="/images/venue-city-icon.svg"><span>{{ result.venue.state }}, {{ result.venue.timezone }}, {{ result.venue.countryCode }}</span></span
                          ></div>
                          <div class="date-time">
                            <span><img class="venue-day-icon" src="/images/venue-day-icon.svg"> {{ getEventDay(result.eventDateString) }}</span>
                            <span><img class="venue-time-icon" src="/images/venue-time-icon.svg"> {{ formatEventTime(result.eventDateString) }}</span>
                          </div>
                          <!-- <div class="venue-address-details">
                            <span class="venue-name">{{ result.venue.name }}</span>
                            <span class="venue-city">{{ result.venue.city }}</span>
                            <span class="venue-state">{{ result.venue.state }}</span>
                            <span class="venue-time">{{ result.venue.timezone }}</span>
                            <span class="venue-country">{{ result.venue.countryCode }}</span>
                          </div> -->
                        </div>
                        <!-- <div class="venue-address-details">
                          <span class="venue-name">{{ result.venue.name }}</span>
                          <span class="venue-city">{{ result.venue.city }}</span>
                          <span class="venue-state">{{ result.venue.state }}</span>
                          <span class="venue-time">{{ result.venue.timezone }}</span>
                          <span class="venue-country">{{ result.venue.countryCode }}</span>
                        </div> -->
                      </div>
                    </div>
                  </a>
                  <h3 class="search-title search-event-title" v-if="results.venues.length>0">Venues</h3>
                  <a :href="`/events/venue/${result.slug}/${result.venueId}`"
                    v-for="(result, i) in results.venues"
                    :key="i"
                    class="performers-listing"
                    :class="{ 'is-active': i === arrowCounter }"
                  >
                  <span class="full-date">{{ result.name }}</span>
                  </a>
                  <div class="show-more-wrap" v-if="results.events.length>0">
                    <button v-on:click="showAllResults" type="button" class="show-more-btn">Show all results</button>
                  </div>
                </div>
              </ul>
            </div>
            <button class="search-btn" type="submit"></button>
          </div>
        </div>
        <BannerSubText />
      </div>
    </section>
    <section class="event-filtering-options">
      <div class="container">
        <div class="event-main-filter-wrapper">
          <div class="event-filtering-section">
            <div class="event-filter event-filter-options common-dropdown-style" v-if="showHomeAwayFilter === true">
              <span class="city-icon"><i class="fa fa-home" aria-hidden="true"></i></span>
              <Select2 ref="sortByHomeAway" v-model="selectedHomeAwayOption" :options="HomeAwaySortingOptions" :settings="{ placeholder: 'Sort By',minimumResultsForSearch:-1 }" @select="searchHomeAway($event)" />
            </div>
            <div class="date-range-picker event-filter-options" v-if="opened" v-click-outside="() => (opened = false)">
              <!-- <label>Select Date </label> -->
              <div class="event-date-picker">
                <span class="close-calendar" v-on:click="removeCalendarbg"><i class="fa fa-times" aria-hidden="true"></i></span>
                <span class="date-icon"><i class="fa fa-calendar" aria-hidden="true"></i></span>
                <date-picker
                  :showHelperButtons="showHelperButtons"
                  :disabledDates="disabledDates"
                  :format="format"
                  :switch-button-initial=true
                  @date-applied="dateApplied"
                  :same-date-format="sameDateFormat"
                  @on-reset="datePickerReset"
                  :dateInput="dateInputConfig"
                  :initialDates="setInitialDates"
                  @datepicker-opened="datePickerOpen"
                />
              </div>
            </div>
            <div class="select-city event-filter-options common-dropdown-style" v-if="isPerformerPage === true">
              <span class="city-icon"><i class="fa fa-building-o" aria-hidden="true"></i></span>
              <Select2 v-model="optionSelected" :options="eventCityList"  :settings="{ placeholder: 'Select City' }" @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
            </div>
            <!-- <div class="select-city event-filter-options" v-if="isPerformerPage === false">
              <LocationDropdown @custom-change="customChangeEvents"/>
            </div> -->
            <!-- <div class="event-sorting vue-select-default event-filter-options common-dropdown-style">
              <span class="city-icon"><i class="fa fa-sort" aria-hidden="true"></i></span>
              <Select2 ref="sortByDropDown" v-model="selectedSortByOption" :options="eventSortingOptions" :settings="{ placeholder: 'Sort By',minimumResultsForSearch:-1 }" @change="myChangeEvent($event)" @select="sortEvents($event)" />
            </div> -->
          </div>
        </div>
      </div>
    </section>
    <section class="section-search-content">
      <div class="container">
        <div class="event-details-page-wrapper">
          <div class="event-details-sidebar-wrapper" v-if="showSimilarEvents === true">
            <div class="related-event-section">
              <div id="primary" class="event-details-rgt-side">
                <div class="similar-performer-desktop">
                  <h3 class="related-event-header">Similar Performers</h3>
                  <div class="related-events-list-wrapper">
                    <div class="related-event-item" v-for="(item) in similarPerformer" :key="item">
                      <router-link :to="`/events/${item.slug}/${item.performerId}`">
                        {{item.name}}
                      </router-link>
                    </div>
                    <p class="no-records-found" v-if="similarPerformer.length <= 0 ">No Similar performers found !</p>
                  </div>
                </div>
                <div class="mobile-similar-performers" id="similarPerformersToggle">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="headingOne">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                        Similar Performers
                      </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#similarPerformersToggle">
                      <div class="similar-performers-listing">
                        <div class="accordion-body" v-for="(item) in similarPerformer" :key="item">
                          <router-link :to="`/events/${item.slug}/${item.performerId}`">
                            {{item.name}}
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="event-details-main-wrapper">
            <div class="event-details-wrapper nearest-event-section" v-bind:class="{ 'mobile-dis-none': nearestEvents.length <= 0 &&  notLoadingNearestEvents}">
              <div id="primary" class="event-details-rgt-side">
                <div class="search-result-header">
                  <div class="event-filter-wrapper">
                    <div class="total-events-show">
                      <h2>Showing {{ nearestEvents.length }} nearest events <span v-if="this.$route.query.q || this.$route.query.venue || this.$route.query.date"> matching your criteria</span></h2>
                    </div>
                  </div>
                </div>
                <div class="search-result-item" v-for="(event) in nearestEvents" :key="event.eventId" :data-href="'/event/' + event.eventId + '/' + event.slug" :data-event-name="event.eventName" :data-venue-name="event.venue.name" :data-venue-city="event.venue.city" :data-venue-state="event.venue.state" :data-venue-timezone="event.venue.timezone" :data-venue-countrycode="event.venue.countryCode" :data-event-date="event.eventDateString" v-on:click="redirectToEventDetailPage">
                  <div class="row">
                    <div class="search-result-item-info col-sm-9">
                      <div class="event-list">
                        <div class="event-date" v-if="!event.eventName.includes('Date TBD')">
                          <div class="date-wrapper">
                            <span class="date">{{ formatEventDateOnly(event.eventDateString) }}</span>
                            <span class="month-year">{{ formatEventDateMonthYear(event.eventDateString) }}</span>
                          </div>
                        </div>
                        <div class="event-date date-tbd" v-else>
                          <div class="date-wrapper">
                            <span class="full-date">Date TBD</span>
                          </div>
                        </div>
                        <div class="event-details">
                          <h3 class="venue-title">{{ event.eventName }}</h3>
                          <div class="venue-address-details">
                            <span class="venue-name"><img class="venue-icon" src="/images/venue-map-icon.svg">{{ event.venue.name }}, {{ event.venue.city }}</span>
                            <span class="venue-state"><img class="venue-icon" src="/images/venue-city-icon.svg">{{ event.venue.state }}, {{ event.venue.timezone }}, {{ event.venue.countryCode }}</span>
                          </div>
                          <div class="date-time">
                            <span class="event-day"><img class="venue-day-icon" src="/images/venue-day-icon.svg"><label>{{ getDayFromDate(event.eventDateString) }}</label></span>
                            <span class="event-time"><img class="venue-time-icon" src="/images/venue-time-icon.svg"><label>{{ formatEventTime(event.eventDateString) }}</label></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="search-result-item-price col-sm-3">
                      <div class="starts-from" v-if="!event.eventName.includes('Date TBD')">
                        <span class="start-label">Onwards</span>
                        <h6 class="event-start-price">${{event.minPrice}}</h6>
                      </div>
                      <div class="starts-from" v-else>
                        <span class="start-label">Onwards</span>
                        <h6 class="event-start-price">${{event.minPrice}}</h6>
                      </div>
                      <button v-on:click="redirectToEventDetailPage" type="button" :data-href="'/event/' + event.eventId + '/' + event.slug" :data-event-name="event.eventName" :data-venue-name="event.venue.name" :data-venue-city="event.venue.city" :data-venue-state="event.venue.state" :data-venue-timezone="event.venue.timezone" :data-venue-countrycode="event.venue.countryCode" :data-event-date="event.eventDateString">Find Tickets</button>
                    </div>
                  </div>
                </div>
                <div class="load-more-spinner" v-bind:class="{ 'dis-none': notLoadingNearestEvents}">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                </div>
                <p class="no-records-found" v-if="nearestEvents.length <= 0 && notLoadingNearestEvents">No Nearest events found !</p>
                <div class="show-more-wrap" v-if="areAllNearestEventsLoaded === false && nearestEvents.length > 0">
                  <button type="button" class="show-more-btn" v-on:click="searchNearestEvents">Show More</button>
                </div>
              </div>
            </div>
            <div class="event-details-wrapper">
              <div id="primary" class="event-details-rgt-side">
                <div class="search-result-header">
                  <div class="event-filter-wrapper">
                    <div class="total-events-show">
                      <h2>Showing all {{ events.length }} events <span v-if="this.$route.query.q || this.$route.query.venue || this.$route.query.date"> matching your criteria</span></h2>
                    </div>
                  </div>
                </div>
                <div class="search-result-item" v-for="(event) in events" :key="event.eventId" :data-href="'/event/' + event.eventId + '/' + event.slug" :data-event-name="event.eventName" :data-venue-name="event.venue.name" :data-venue-city="event.venue.city" :data-venue-state="event.venue.state" :data-venue-timezone="event.venue.timezone" :data-venue-countrycode="event.venue.countryCode" :data-event-date="event.eventDateString" v-on:click="redirectToEventDetailPage">
                  <div class="row">
                    <div class="search-result-item-info col-sm-9">
                      <div class="event-list">
                        <div class="event-date" v-if="!event.eventName.includes('Date TBD')">
                          <div class="date-wrapper">
                            <span class="date">{{ formatEventDateOnly(event.eventDateString) }}</span>
                            <span class="month-year">{{ formatEventDateMonthYear(event.eventDateString) }}</span>
                          </div>
                        </div>
                        <div class="event-date date-tbd" v-else>
                          <div class="date-wrapper">
                            <span class="full-date">Date TBD</span>
                            <div class="date-time">
                            </div>
                          </div>
                        </div>
                        <div class="event-details">
                          <h3 class="venue-title">{{ event.eventName }}</h3>
                          <div class="venue-address-details">
                            <span class="venue-name"><img class="venue-icon" src="/images/venue-map-icon.svg">{{ event.venue.name }}, {{ event.venue.city }}</span>
                            <span class="venue-state"><img class="venue-icon" src="/images/venue-city-icon.svg">{{ event.venue.state }}, {{ event.venue.timezone }}, {{ event.venue.countryCode }}</span>
                          </div>
                          <div class="date-time">
                            <span class="event-day"><img class="venue-day-icon" src="/images/venue-day-icon.svg"><label>{{ getDayFromDate(event.eventDateString) }}</label></span>
                            <span class="event-time"><img class="venue-time-icon" src="/images/venue-time-icon.svg"><label>{{ formatEventTime(event.eventDateString) }}</label></span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="search-result-item-price col-sm-3">
                      <div class="starts-from" v-if="!event.eventName.includes('Date TBD')">
                        <span class="start-label">Starts from</span>
                        <h6 class="event-start-price">${{event.minPrice}}</h6>
                      </div>
                      <div class="starts-from" v-else>
                        <span class="start-label">starts from</span>
                        <h6 class="event-start-price">${{event.minPrice}}</h6>
                      </div>
                      <button v-on:click="redirectToEventDetailPage" type="button" :data-href="'/event/' + event.eventId + '/' + event.slug" :data-event-name="event.eventName" :data-venue-name="event.venue.name" :data-venue-city="event.venue.city" :data-venue-state="event.venue.state" :data-venue-timezone="event.venue.timezone" :data-venue-countrycode="event.venue.countryCode" :data-event-date="event.eventDateString">Find Tickets</button>
                    </div>
                  </div>
                </div>
                <div class="load-more-spinner" v-bind:class="{ 'dis-none': notLoadingEvents}">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                </div>
                <p class="no-records-found" v-if="events.length <= 0 && notLoadingEvents">No events found !</p>
                <div class="show-more-wrap" v-if="areAllEventsLoaded === false && events.length > 0">
                  <button type="button" class="show-more-btn" v-on:click="searchEvents">Show More</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
<script>
// import $ from 'jquery'
import axios from 'axios'
import moment from 'moment'
// eslint-disable-next-line
// import _ from 'lodash'
// import 'vue-next-select/dist/index.css'
// import EventSearchAutocomplete from '../../views/autocomplete/EventSearchAutocomplete'
// import MobileEventSearchAutocomplete from '../../views/autocomplete/MobileEventSearchAutocomplete'
import Cookies from 'js-cookie'
import VLazyImage from 'v-lazy-image'
import BannerSubText from './../../components/layouts/BannerSubText.vue'
import DatePicker from 'vue-time-date-range-picker/dist/vdprDatePicker'
import 'vue-time-date-range-picker/dist/vdprDatePicker.min.css'
import Select2 from 'vue3-select2-component'
// import LocationDropdown from '../../components/layouts/header/Location.vue'
// import { thisExpression } from '@babel/types'
// import { toRaw } from 'vue'
/* eslint-disable */
export default {
    name: 'Events',
    components: {
      VLazyImage,
      BannerSubText,
      DatePicker,
      // LocationDropdown,
      Select2
    },
    beforeCreate () {
      // var performer = this.$route.params.performerName ? this.$route.params.performerName.replace(/(-)/g, " ") : this.$route.query.q
      // var performer = performer ? performer.charAt(0).toUpperCase() + performer.slice(1) : 'Events'
      // document.title = performer + ' | ' + process.env.VUE_APP_NAME
    },
    data: function () {
      return {
        page: 1,
        nearestPage: 1,
        limit: 30,
        nearestEventsLimit: 5,
        nearestLimit: 20,
        areAllEventsLoaded: false,
        areAllNearestEventsLoaded: false,
        debounce: false,
        isPerformerPage: false,
        notLoadingEvents: true,
        notLoadingNearestEvents: true,
        nearestEvents: [],
        events: [],
        categoryEvents: [],
        eventCategories: [],
        categoriesUngrouped: [],
        filteringForCategory: [],
        eventCityList: [{id: "all", text: "All Locations"}],
        eventCity: [],
        optionSelected: this.$route.query.venue ? this.$route.query.venue : 'all',
        areCategoriesLoaded: false,
        areEventsLoaded: false,
        date: this.$route.query.date,
        selectedCategory: null,
        selectedSortByOption: this.$route.query.sortBy ? ['date-asc'] : ['date-desc'],
        selectedHomeAwayOption: this.$route.query.homeAway ? this.$route.query.homeAway === 'home' ? ['home'] :['away'] : ['all'],
        isOpen: false,
        opened: true,
        results: [],
        search: this.$route.query.q,
        isLoading: false,
        arrowCounter: -1,
        GlobalSearchPlaceholder: 'Search for performers and events',
        isGlobalSearchNotInFocus: true,
        eventFilterCityId: this.$route.query.venue || this.$route.query.homeVenue ? this.$route.query.venue || this.$route.query.homeVenue : this.$route.params.venueId ? this.$route.params.venueId : '',
        eventFilterStartDate: this.$route.query.startDate ? this.$route.query.startDate : '',
        eventFilterEndDate: this.$route.query.endDate ? this.$route.query.endDate : '',
        sortBy: this.$route.query.sortBy ? 'oldest' : '',
        setInitialDates: this.$route.query.startDate && this.$route.query.endDate ? [new Date(this.$route.query.startDate), new Date(this.$route.query.endDate)]: [],
        bannerTitle: '',
        noEventsFound: false,
        singlePerformer: [],
        singleVenue: [],
        singleRegion: [],
        singleVenueName: [],
        singleRegionName: [],
        similarPerformer: [],
        singlePerformerName: '',
        // eventTypeFilter: this.$route.query.categorieName ? this.$route.query.categorieName : '', 
        eventTypeFilter: this.$route.params.categorieName ? this.$route.params.categorieName : '', 
        // regionIdFilterName: this.$route.query.region ? this.$route.query.region : '', 
        // regionIdFilter: this.$route.query.regionId ? this.$route.query.regionId : '', 
        regionIdFilterName: this.$route.params.regionName ? this.$route.params.regionName : '', 
        regionIdFilter: this.$route.params.regionId ? this.$route.params.regionId : '', 
        eventHomeAway: this.$route.query.homeAway ? this.$route.query.homeAway === 'home' ? 'home' :'away' : '',
        eventCompareName: this.$route.query.performerName ? this.$route.query.performerName : '',
        showHomeAwayFilter: false,
        showSimilarEvents: false,
        eventHomeAwayVenue: '',
        active_el: this.$route.query.homeAway ? this.$route.query.homeAway == 'home' ? 2 :3 : 1,
        userSearchLocation: Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')) : '',
        userLocation: Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')) : '',
        latitude: '',
        longitude:'',
        dateInput: {
          placeholder: 'Date',
        },
        format: 'MM-DD-YYYY',
        sameDateFormat: {
          from: 'MM-DD-YYYY',
          to: 'MM-DD-YYYY'
        },
        dateInputConfig: {
          id: 'eventsDateRangeFilter',
          placeholder: 'Date',
        },
        showHelperButtons: true,
        disabledDates: {
          to: new Date(new Date().setDate(new Date().getDate() - 1))
        },
        eventSortingOptions: [
          {
            id: 'date-asc',
            text: 'Date - Older first'
          },
          {
            id: 'date-desc',
            text: 'Date - Latest first'
          }
        ],
        HomeAwaySortingOptions: [
          {
            id: 'all',
            text: 'Home/Away'
          },
          {
            id: 'home',
            text: 'Home'
          },
          {
            id: 'away',
            text: 'Away'
          }
        ],
        userEmail: Cookies.get('userEmail'),
      }
    },
    watch: {
      $route (to, from) {
        this.events = []
        this.areEventsLoaded = false
        // this.searchEvents()
      },
      items: function(value, oldValue) {
        if (value.length !== oldValue.length) {
          this.results = value;
          this.isLoading = false;
        }
      }
    },
    mobileEventSearch: '.mobile-event-search-jquery',
    mounted () {
      if (this.userSearchLocation) {
        this.latitude = this.userSearchLocation.lat
        this.longitude = this.userSearchLocation.lang
      } else {
        if (this.userLocation){
          var location = this.userLocation.loc
          this.latitude = location.split(',')[0]
          this.longitude = location.split(',')[1]
        }
      }
      // this.getCategories()
      if (this.$route.query.startDate && this.$route.query.endDate) {
        document.getElementsByClassName("vdpr-datepicker")[0].getElementsByTagName('input')[0].style.width='225px'
        // $(".vdpr-datepicker input").css('width','225px')
      }
      if(this.$route.params.performerId){
        this.isPerformerPage =true
        this.getSinglePerformer()
        this.searchEventCities()
      } else {
        if (this.$route.query.q) {
          document.title = 'Search Results | ' + process.env.VUE_APP_NAME
        } else {
          document.title = 'Events for you | ' + process.env.VUE_APP_NAME
        }
      }
      if(this.$route.params.venueId){
        this.getSingleVenue()
      }
      if(this.$route.params.regionId){
        this.getSingleRegion()
      }
      // this.searchNearestEvents()
      this.searchEvents()
      // this.bannerTitle = this.$route.params.performerName ? this.$route.params.performerName.replace(/(-)/g, " ") : this.$route.query.q
      this.bannerTitle = this.$route.query.q
      this.bannerTitle = this.bannerTitle ? 'Events for ' + this.bannerTitle.charAt(0).toUpperCase() + this.bannerTitle.slice(1) : 'Events for you'
      document.addEventListener("click", this.handleClickOutside);
      // this.$el.addEventListener('click', this.handleClickOutside)
      this.$el.addEventListener('click', ({ target }) => {
        document.body.classList.add("event-search-blur");
      })
      this.$el.addEventListener('click', ({ target }) => {
        document.getElementsByClassName("vdpr-datepicker__button-reset").setAttribute("value", "");
      })
      // $("body").on("click", '.mobile-event-search-jquery', function (e) {
      //   $('body').addClass('event-search-blur');
      // });
      // $("body").on("click", '.mobile-event-search-jquery', function (e) {
      //   $('.vdpr-datepicker__button-reset').val('')
      // });
    },
    destroyed() {
      // this.$el.addEventListener('click', this.handleClickOutside)
      document.removeEventListener("click", this.handleClickOutside);
    },
    methods: {
      searchHomeAway({ id, text, selected }) {
        this.page = 1;
        this.events =[];
        const query = Object.assign({}, this.$route.query);
        if (id === 'all'){
          this.eventHomeAway = ''
          this.eventCompareName = ''
          delete query.homeAway;
          delete query.venue;
          delete query.homeVenue;
          this.eventFilterCityId = '';
          this.$router.replace({ query });
        }else{
          this.eventHomeAway = id;
          this.eventFilterCityId = this.eventHomeAwayVenue ? this.eventHomeAwayVenue : '12345';
          // console.log(this.eventHomeAwayVenue);
          if (id === 'home'){
            if (this.eventHomeAwayVenue) {
              const query = Object.assign({}, this.$route.query, { homeAway: this.eventHomeAway, venue: this.eventFilterCityId });
              delete query.homeVenue;
              this.$router.replace({ query });
            }
          } else {
            if (this.eventHomeAwayVenue) {
              const query = Object.assign({}, this.$route.query, { homeAway: this.eventHomeAway, homeVenue: this.eventFilterCityId });
              delete query.venue;
              this.$router.replace({ query });
            }
          }
        }
        this.searchEvents()
      },
      myChangeEvent(val) {
      },
      mySelectEvent({ id, text, selected }) {
        this.page = 1;
        this.events =[];
        if(id === 'all'){
          this.eventFilterCityId = '';
          this.searchEvents()
          const query = Object.assign({}, this.$route.query);
          delete query.venue;
          this.$router.replace({ query });
        }else{
          this.eventFilterCityId = id
          this.$router.push({ query: Object.assign({}, this.$route.query, { venue: this.eventFilterCityId }) });
          this.searchEvents()
        }
      },
      dateApplied (date1, date2) {
        document.getElementsByClassName("vdpr-datepicker")[0].getElementsByTagName('input')[0].style.width='225px'
        // $(".vdpr-datepicker input").css('width','225px')
        this.eventFilterStartDate = moment(date1).format('YYYY-MM-DD')
        this.eventFilterEndDate = moment(date2).format('YYYY-MM-DD')
        this.page = 1;
        this.searchEvents()
        this.$router.push({ query: Object.assign({}, this.$route.query, { startDate: this.eventFilterStartDate, endDate: this.eventFilterEndDate }) });
        document.body.classList.remove('date-picker-open')
      },
      datePickerReset (e) {
        document.getElementsByClassName("vdpr-datepicker__button-reset")[0].setAttribute("value", "");
        // $('.vdpr-datepicker__button-reset').val('')
        this.eventFilterStartDate = ''
        this.eventFilterEndDate = ''
        this.events = [];
        this.page = 1;
        this.searchEvents()
        const query = Object.assign({}, this.$route.query);
        delete query.startDate;
        delete query.endDate;
        this.$router.replace({ query });
        document.getElementsByClassName("vdpr-datepicker__calendar-dialog")[0].style.display = "none"
        document.body.classList.remove('date-picker-open')
      },
      sortEvents: function ({ id, text, selected }) {
        this.page = 1
        this.events = [];
        this.sortBy = id == 'date-asc' ? 'oldest' : ''
        this.searchEvents()
        if(this.sortBy === 'oldest'){
          this.$router.push({ query: Object.assign({}, this.$route.query, { sortBy: this.sortBy }) });
        }else{
          const query = Object.assign({}, this.$route.query);
          delete query.sortBy;
          this.$router.replace({ query });
        }      
      },
      slugToString: function (slug) {
        if (slug.indexOf(/-/g, ' ') != -1) {
          return slug.replace(/-/g, ' ')
        } else {
          return slug
        }
      },
      blurBackground: function (e) {
        this.isGlobalSearchNotInFocus = false
        document.body.classList.add("global-search-blur");
        document.getElementsByClassName('section-header')[0].classList.add('global-search-blur-banner');
        document.getElementById("mobileEventSearchInput").setAttribute("placeholder", "");
        // $('body').addClass('event-search-blur')
        // $('#mobileEventSearchInput').attr('placeholder', '')
      },
      removeBlurFromBody: function (e) {
        this.isGlobalSearchNotInFocus = true
        document.body.classList.remove("global-search-blur");
        document.getElementsByClassName('section-header')[0].classList.remove('global-search-blur-banner');
        document.getElementById("mobileEventSearchInput").setAttribute("placeholder", this.GlobalSearchPlaceholder);
        // $('body').removeClass('event-search-blur')
        // $('#mobileEventSearchInput').attr('placeholder', this.GlobalSearchPlaceholder)
      },
      showAllResults: function (e) {
        document.body.classList.remove("event-search-blur");
        document.getElementsByClassName("mobile-search-icon-j")[0].classList.remove('mobile-search');
        var autocompleteResults = document.getElementsByClassName("autocomplete-results")[0];
        autocompleteResults.style.display = "none";
        // $('body').removeClass('event-search-blur')
        // $('.mobile-search-icon-j').removeClass('mobile-search')
        // $('.autocomplete-results').hide()
        this.$router.push({
          path: '/events',
          query: { q: this.$refs.mobileEventSearchInput.value }
        })
      },
      formatEventDate: function (date) {
        return moment(date).format('Do MMMM, YYYY')
      },
      formatEventDateOnly: function (date) {
        return moment(date).format('Do')
      },
      formatEventDateMonthYear: function (date) {
        return moment(date).format('MMM, YYYY')
      },
      formatEventTime: function (date) {
        return moment(date).format('hh:mm a')
      },
      getEventDay: function (date) {
        return moment(date).format('dddd')
      },
      setResult(result) {
        this.search = result.name || result.eventName;
        this.isOpen = false;
      },
      filterResults() {
        this.isLoading = true;
        this.results = [];
        axios.get(process.env.VUE_APP_API_URL + 'globalSearch', {
          params: {
            q: this.$refs.mobileEventSearchInput.value,
            venueRegion: Cookies.get('userLocation')
          }
        }).then(response => {
          this.isLoading = false;
          this.results = response.data.data.results
        })
      },
      onChange() {
        this.$emit("input", this.search);
        if (! this.isAsync) {
          clearTimeout(this.debounce)
            this.debounce = setTimeout(() => {
            this.filterResults();
          }, 300)
          // this.filterResults();
         this.isOpen = true;
        }
      },
      handleClickOutside(event) {
        this.search = ''
        if (!this.$el.contains(event.target)) {
          this.isOpen = false;
          this.arrowCounter = -1;
        }
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1;
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1;
        }
      },
      onEnter() {
        window.location.href = process.env.BASE_URL + 'events?q=' + this.$refs.mobileEventSearchInput.value
        /* this.search = this.results[this.arrowCounter];
        this.isOpen = false;
        this.arrowCounter = -1; */
        this.showAllResults()
      },
      filterEventsByEventCategory: function () {
        this.$router.push({
          path: 'events',
          query: { q: this.selectedCategory, venue: this.$refs.eventFilterVenue.value, date: this.$refs.eventFilterDate.value }
        })
      },
      searchEventsLocally: function () {
        this.$router.push({
          path: 'events',
          query: { q: this.$refs.eventFilterKeyword.value, venue: this.$refs.eventFilterVenue.value, date: this.$refs.eventFilterDate.value /* category: this.$route.query.category */ }
        })
      },
      redirectToEventDetailPage: function (e) {
        window.location = window.location.origin + e.currentTarget.getAttribute('data-href')
      },
      getSinglePerformer: function () {
        axios.get(process.env.VUE_APP_API_URL + 'getSinglePerformer', {
          params: {
            performerID: this.$route.params.performerId
          }
        }).then(response => {
          if (response.status === 200) {
            if (response.data.data.performer.category.name.substring(0, 3) === 'MLB' ||
            response.data.data.performer.category.name.substring(0, 3) === 'NFL' ||
            response.data.data.performer.category.name.substring(0, 3) === 'NHL' ||
            response.data.data.performer.category.name.substring(0, 3) === 'NBA' ) {
              this.showHomeAwayFilter = true
              this.getPerformcerCity(response.data.data.performer.name)
              this.klavioTracking(response.data.data.performer.name, this.$route.params.performerId)
            }
            this.getSimilarPerformer(response.data.data.performer.category.categoryId)
            this.showSimilarEvents = true
            this.singlePerformer.push(response.data.data.performer)
            this.singlePerformerName = response.data.data.performer.name
            document.title = this.singlePerformerName + ' | ' + process.env.VUE_APP_NAME
          }else{
            this.klavioTracking("All events", "No performer")
          }
        })
      },
      klavioTracking: function (catName, catID) {
      if(process.env.VUE_APP_KLAVIYO && this.userEmail){
        const options = {
        method: 'POST',
        headers: {accept: 'text/html', 'content-type': 'application/x-www-form-urlencoded'},
        body: new URLSearchParams({
          data: '{"token": "'+process.env.VUE_APP_KLAVIYO_CODE+'", "event": "viewed product", "customer_properties": {"$email": "'+ this.userEmail +'"}, "properties": {"item_name": "'+catName+'","$event_id": "'+catID+'"}}'
        })
      };
      fetch('https://a.klaviyo.com/api/track', options)
        .then(response => response.json())
        .then(response => response)
        .catch(err => err);
      }
    },
      getPerformcerCity: function (name) {
        this.singlePerformerName = name
        axios.get(process.env.VUE_APP_API_URL + 'getPerformcerCity', {
          params: {
            performerID: this.$route.params.performerId,
            eventCompareName: this.singlePerformerName ? this.singlePerformerName : this.$route.params.performerName
          }
        }).then(response => {
          if (response.status === 200) {
            if (response.data.data) {
              this.eventHomeAwayVenue = response.data.data.venue.venueId
            }
          }
        })
      },
      getSingleVenue: function () {
        axios.get(process.env.VUE_APP_API_URL + 'getSingleVenue', {
          params: {
            venueId: this.$route.params.venueId,
          }
        }).then(response => {
          if (response.status === 200) {
            if (response.data.data) {
              this.singleVenue = response.data.data.venue
              this.singleVenueName = response.data.data.venue.name
            }
          }
        })
      },
      getSingleRegion: function () {
        axios.get(process.env.VUE_APP_API_URL + 'getSingleRegion', {
          params: {
            regionId: this.$route.params.regionId,
          }
        }).then(response => {
          if (response.status === 200) {
            if (response.data.data) {
              this.singleRegion = response.data.data.region
              this.singleRegionName = response.data.data.region.name
            }
          }
        })
      },
      getSimilarPerformer: function (catId) {
        axios.get(process.env.VUE_APP_API_URL + 'getSimilarPerformer', {
          params: {
            categoryId: catId,
            performerID: this.$route.params.performerId,
          }
        }).then(response => {
          if (response.status === 200) {
            if (response.data.data) {
              this.similarPerformer = response.data.data.performer
            }
          }
        })
      },
      customChangeEvents (value) {
        this.nearestEvents = []
        this.events = []
        this.nearestPage = 1
        this.page = 1
        this.userSearchLocation = Cookies.get('userSearchLocation') ? JSON.parse(Cookies.get('userSearchLocation')) : ''
        this.userLocation = Cookies.get('userLocation') ? JSON.parse(Cookies.get('userLocation')) : ''
        if (this.userSearchLocation) {
        this.latitude = this.userSearchLocation.lat
        this.longitude = this.userSearchLocation.lang
      } else {
        if (this.userLocation){
          var location = this.userLocation.loc
          this.latitude = location.split(',')[0]
          this.longitude = location.split(',')[1]
        }
      }
        // this.searchNearestEvents()
        this.searchEvents()
      },
      searchNearestEvents: function () {
        // this.events = []
        // this.areEventsLoaded = false
        this.notLoadingNearestEvents = false
        axios.get(process.env.VUE_APP_API_URL + 'getEvents', {
          headers: {
            Authorization: process.env.VUE_APP_TFS_AUTHORIZATION
          },
          params: {
            page: this.nearestPage,
            limit: this.nearestEventsLimit,
            eventName: this.$refs ? this.$refs.mobileEventSearchInput.value : '',
            eventCompareName: this.eventCompareName,
            performerId: this.$route ? this.$route.params.performerId : '',
            venueRegion: Cookies.get('userLocation'),
            venueId: this.eventFilterCityId,
            sortBy: this.sortBy,
            homeAway: this.eventHomeAway,
            startDate: moment(this.eventFilterStartDate).format('YYYY-MM-DD' + 'T00:00:00'),
            endDate: moment(this.eventFilterEndDate).format('YYYY-MM-DD' + 'T23:59:00'),
            lang: this.longitude,
            lat: this.latitude,
            regionId: this.regionIdFilter,
            eventType: this.eventTypeFilter,
            locationProspect: 'nearest'
          }
        }).then(response => {
          if (response.status === 200) {
            var vm = this
            // this.events = response.data.data
            this.notLoadingNearestEvents = true
            this.nearestPage++
            if (response.data.data.length <= 0) {
              this.areAllNearestEventsLoaded = true
              this.nearestEvents = []
            } else {
              this.nearestEvents.push(...response.data.data)
              if (response.data.data.length < this.nearestEventsLimit) {
                this.areAllNearestEventsLoaded = true
              }
            }
            // this.sortEvents(this.eventSortingOptions[0])
            this.areNearestEventsLoaded = true
            
          }
        })
      },
      searchEvents: function () {
        // this.events = []
        // this.areEventsLoaded = false
        this.notLoadingEvents = false
        axios.get(process.env.VUE_APP_API_URL + 'getEvents', {
          headers: {
            Authorization: process.env.VUE_APP_TFS_AUTHORIZATION
          },
          params: {
            page: this.page,
            limit: this.limit,
            eventName: this.$refs ? this.$refs.mobileEventSearchInput.value : '',
            eventCompareName: this.eventCompareName,
            performerId: this.$route ? this.$route.params.performerId : '',
            venueRegion: Cookies.get('userLocation'),
            venueId: this.eventFilterCityId,
            sortBy: this.sortBy,
            homeAway: this.eventHomeAway,
            startDate: moment(this.eventFilterStartDate).format('YYYY-MM-DD' + 'T00:00:00'),
            endDate: moment(this.eventFilterEndDate).format('YYYY-MM-DD' + 'T23:59:00'),
            lang: this.longitude,
            lat: this.latitude,
            regionId: this.regionIdFilter,
            eventType: this.eventTypeFilter,
            locationProspect: 'farther'
          }
        }).then(response => {
          if (response.status === 200) {
            var vm = this
            // this.events = response.data.data
            this.notLoadingEvents = true
            this.page++
            if (response.data.data.length <= 0) {
              this.areAllEventsLoaded = true
              this.events = []
            } else {
              this.events.push(...response.data.data)
              if (response.data.data.length < this.limit) {
                this.areAllEventsLoaded = true
              }
            }
            // this.sortEvents(this.eventSortingOptions[0])
            this.areEventsLoaded = true
            
          }
        })
      },
      searchEventCities: function () {
        /* eslint-disable */
        // this.events = []
        // this.areEventsLoaded = false
        axios.get(process.env.VUE_APP_API_URL + 'getEventCities', {
          headers: {
            Authorization: process.env.VUE_APP_TFS_AUTHORIZATION
          },
          params: {
            eventName: this.$refs.mobileEventSearchInput.value,
            performerId: this.$route.params.performerId,
            venueRegion: Cookies.get('userLocation'),
            // startDate: moment(this.eventFilterStartDate).format('YYYY-MM-DD' + 'T00:00:00'),
            // endDate: moment(this.eventFilterEndDate).format('YYYY-MM-DD' + 'T23:59:00')
          }
        }).then(response => {
          if (response.status === 200) {
            this.eventCity.push(...response.data.data)
            this.eventCity
            .sort((a,b) => a._id > b._id ? 1 : -1)
            .map(x => {
              this.eventCityList.push({id:x.venueID , text: x.venueName})
              })
          }
        })
      },
      // getCategories: function () {
      //   axios.get(process.env.VUE_APP_TFS_API_URL + '/getCategories', {
      //     headers: {
      //       Authorization: process.env.VUE_APP_TFS_AUTHORIZATION
      //     },
      //     params: {
      //       accessId: process.env.VUE_APP_TFS_ACCESS_ID
      //     }
      //   }).then(response => {
      //     if (response.status === 200) {
      //       this.areCategoriesLoaded = true
      //       this.categoriesUngrouped = response.data
      //       this.eventCategories = _.groupBy(response.data, 'eventType')
      //     }
      //   })
      // },    
      getDayFromDate: function (date) {
        return moment(date).format('dddd')
      },
      formatEventDate: function (date) {
        return moment(date).format('Do MMMM, YYYY')
      },
      formatEventTime: function (date) {
        return moment(date).format('hh:mm a')
      },
      redirectToSearchEvents: function (event) {
        if (this.$refs.redirectToSearchEventsInput.value) {
          this.$router.push({
            query: { q: this.$refs.redirectToSearchEventsInput.value },
            params: { performerName: this.$route.params.performerName, performerId: this.$route.params.performerId }
          })
          // window.location = window.location.origin + '/events/' + this.$route.params.performerName + '/' + this.$route.params.performerId + '?q=' + this.$refs.redirectToSearchEventsInput.value
          // window.location = window.location.origin + '/events?q=' + this.$refs.redirectToSearchEventsInput.value
        }
      },
      datePickerOpen: function (e) {
        document.body.classList.add("date-picker-open");
        // $('body').addClass('date-picker-open')
      },
      removeCalendarbg: function (e) {
        document.body.classList.remove("date-picker-open");
        // $('body').removeClass('date-picker-open')
        document.getElementsByClassName("vdpr-datepicker__calendar-dialog")[0].style.display = "none"
      },
    },
    directives: {
    'click-outside': {
      beforeMount: function(el, binding, vnode) {
        el.clickOutsideEvent = evt => {
        evt.stopPropagation()
          if (!(el === evt.target || el.contains(evt.target))) {
            document.body.classList.remove("date-picker-open");
            document.getElementsByClassName("vdpr-datepicker__calendar-dialog")[0].style.display = "none"
          }
        }
        window.requestAnimationFrame(() => {
          document.addEventListener('click', el.clickOutsideEvent)
        })
      },
      unmounted(el) {
        document.removeEventListener('click', el.clickOutsideEvent)
      },
    }},
    props: {
      items: {
        type: Array,
        required: false,
        default: () => []
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false
      }
    }
  }
  </script>
  <style>
  .autocomplete {
    position: relative;
  }
  
  .autocomplete-results {
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
  }
  
  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }
  
  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #4aae9b;
    color: white;
  }
  .none {
    display: none;
  }
  </style>
